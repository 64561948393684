import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Footer from "../components/Footer";
import Cookies from "../common/Cookies";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  const [cookies] = useCookies(['cookieAgreement']);
  const cookieAgreement = cookies.cookieAgreement
  const isShowCookiesInfo = cookieAgreement === 'accepted' || cookieAgreement === 'rejected'
  
  return (
    <Suspense fallback={null}>
      <Styles />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
      {!isShowCookiesInfo && <Cookies />}
    </Suspense>
  );
};

export default Router;
