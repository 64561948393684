import styled from "styled-components";
import { Button } from 'antd';

export const Container = styled("section")`
    position: fixed;
    max-width: 45%;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin-left: 3rem;
    background-color: white;
    padding: 1em;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.41);

    @media only screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100%;
    }
`;

export const Title = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 0;
`

export const H6 = styled("h6")`
    font-size: 24px;
    margin: 0;
    margin-left: .25rem;
`

export const Text = styled("p")`
    font-size: 14px;
`

export const TextButton = styled("p")`
    font-size: 14px;
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
    }
`

export const SmallTitle = styled("p")`
    font-size: 16px;
    font-weight: bolder;
    margin: .25rem 0;
`

export const Ul = styled("ul")`
    
`

export const Li = styled("li")`
    font-size: 14px;
    color: rgb(24, 33, 109);

    &::marker {
        color: rgb(233, 58, 125);
    }
`

export const ButtonsContainer = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
`

export const StyledButton = styled(Button)`
    background: rgb(46, 24, 106);
    color: rgb(255, 255, 255);
    font-weight: 700;
    width: 100%;
    border: 1px solid rgb(237, 243, 245);
    border-radius: 4px;
    cursor: pointer;
    max-width: 180px;
    transition: 0.3s ease-in-out;

    &:hover {
        color: rgb(255, 255, 255);
        border: 1px solid rgb(255, 130, 92);
        background-color: rgb(255, 130, 92);
    }
    &:active {
        color: rgb(255, 255, 255);
        border: 1px solid rgb(255, 130, 92);
        background-color: rgb(255, 130, 92);
    }
    &:focus {
        color: rgb(255, 255, 255);
        border: 1px solid rgb(255, 130, 92);
        background-color: rgb(255, 130, 92);
    }
`