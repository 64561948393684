import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.css';
import { inject } from '@vercel/analytics';
import Router from './router';
import i18n from './translation';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);
const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);
root.render(<App />);

inject();
