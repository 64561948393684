// src/components/Footer/index.tsx
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { SvgIcon } from '../SvgIcon';
import { Container, Title, H6, Text, SmallTitle, Ul, Li, ButtonsContainer, StyledButton, TextButton } from './styles';

const Cookies: React.FC<WithTranslation> = ({ t }) => {
    const [isShowed, setIsShowed] = useState(false)
    const [cookies, setCookie] = useCookies(['cookieAgreement'])

    const handleChange = (status:boolean) => {
        setIsShowed(!status)
    }

    const expiresDate = new Date()
    expiresDate.setTime(expiresDate.getTime() + (100 * 365 * 24 * 60 * 60 * 1000)) // 100 years

    const setCookieAgreement = (agreement:string) => {
        setCookie('cookieAgreement', agreement, { path: '/', expires: expiresDate })
        setCookie('cookieAgreement', agreement, { path: '/', expires: expiresDate });
    }


    const buttonText = isShowed ? t("Less information") : t("More information")
     
    return (
        <Container>
            <Title>
                <SvgIcon src="cookies.svg" width='32' height='32'/>
                <H6>{t("Cookies Policy")}</H6>
            </Title>
            <Text>{t("To enhance your experience on our website, we use cookies to gather and analyze information about how you interact with our content. Cookies help us improve our services, personalize content, and provide relevant advertising.")}</Text>
            <ButtonsContainer>
                <StyledButton
                    onClick={() => setCookieAgreement('rejected')}
                >
                    {t("Reject Cookies")}
                </StyledButton>
                <StyledButton
                    onClick={() => setCookieAgreement('accepted')}
                >
                    {t("Accept Cookies")}
                </StyledButton>
            </ButtonsContainer>
            {isShowed && (
                <>
                    <SmallTitle>{t("What Are Cookies?")}</SmallTitle>
                    <Text>{t("Cookies are small files stored on your device that allow us to remember your preferences and provide a more seamless experience.")}</Text>
                    <SmallTitle>{t("How We Use Cookies")}</SmallTitle>
                    <Text>{t("We use cookies to:")}</Text>
                    <Ul>
                        <Li>{t("Recognize you when you return to our website.")}</Li>
                        <Li>{t("Understand how you interact with our site.")}</Li>
                        <Li>{t("Customize your browsing experience.")}</Li>
                        <Li>{t("Provide targeted and relevant advertisements.")}</Li>
                    </Ul>
                    <SmallTitle>{t("Your Control Over Cookies")}</SmallTitle>
                    <Text>{t("You can manage, delete, or disable cookies through your browser settings. Please note that disabling cookies may impact some of our website's functionalities.")}</Text>
                </>
            )}
            <TextButton onClick={() => handleChange(isShowed)}>{buttonText}</TextButton>
        </Container>
    )
};

export default withTranslation()(Cookies);

